<template>
  <div v-if="!$oauth.hasAllConnections(profile) && providerUrls">
    <v-card-title class="subtitle">{{$t('profile.connections.panel-header')}}</v-card-title>
    <v-card-text class="pb-0">{{$t('profile.connections.intro')}}</v-card-text>
    <v-card-text class="connect-button-container">      
      <v-btn v-if="tenant.enableStrava && !$oauth.isConnected(profile, 'strava')" :href="providerUrls.strava" color="#FC4C02" class="connect-button mr-4 mb-4">
        <img src="/images/btn_strava_connectwith_orange.svg" style="height:42px"/>
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'garmin')" :href="providerUrls.garmin" color="#11BFED" class="connect-button mr-4 mb-4">
        <img src="/images/garmin-connect.png" width="26" height="26" alt="Garmin Connect">
        Connect with Garmin
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'fitbit')" :href="providerUrls.fitbit" color="#4cc2c4" class="connect-button mr-4 mb-4 white--text">
        <span style="font-size:70%;">Connect with</span>
        <img src="/images/fitbit.png" height="22" alt="Fitbit" class="ml-2">
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'oura')" :href="providerUrls.oura" color="rgb(47, 74, 115)" class="connect-button mr-4 mb-4 white--text">
        <span style="font-size:80%;" class="mr-1">Connect with</span>
        <img src="https://sodisp.imgix.net/web/sso/oura-ring-white.png?h=40" height="20" alt="Oura Ring" class="ml-1 mr-2">
        <!-- <strong style="font-size:80%; text-transform:uppercase;">Oura Ring</strong> -->
      </v-btn>
 
      <v-btn v-if="!$oauth.isConnected(profile, 'whoop')" :href="providerUrls.whoop" color="black" class="connect-button mr-4 mb-4 white--text">
        <span style="font-size:80%;" class="mr-1">Connect with</span>
        <img src="https://sodisp.imgix.net/web/sso/whoop.png?h=40" height="20" alt="Whoop" class="ml-1 mr-2">
      </v-btn> 

      <v-btn v-if="!$oauth.isConnected(profile, 'polar')" :href="providerUrls.polar" color="#d10027" class="connect-button mr-4 mb-4 white--text">
        <span style="font-size:70%;">Connect with</span>
        <img src="/images/polar.png" height="22" alt="Polar" class="ml-2">
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'suunto')" :href="providerUrls.suunto" color="#147eab" class="connect-button mr-4 mb-4 white--text">
        <span style="font-size:70%;">Connect with</span>
        <img src="/images/suunto.png" height="24" alt="Suunto" class="ml-2">
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'coros')" :href="providerUrls.coros" color="#F8283B" class="connect-button mr-4 mb-4 white--text">
        <span style="font-size:70%;">Connect with</span>
        <img src="/images/coros.png" height="14" alt="Coros" class="ml-2">
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'underarmour')" :href="providerUrls.underarmour" color="#204889" class="connect-button mr-4 mb-4 white--text">
        <img src="/images/mapmyfitness-white.png" height="20" alt="MapMyFitness" class="mr-2">
        <span style="font-size:80%;" class="mr-1">Connect with</span>
        <strong style="font-size:80%; text-transform:uppercase;">MapMyFitness</strong>
      </v-btn>

      <v-btn v-if="!$oauth.isConnected(profile, 'concept2')" :href="providerUrls.concept2" color="#033B78" class="connect-button mr-4 mb-4 white--text">
        <span style="font-size:80%;" class="mr-1">Connect with</span>
        <strong style="font-size:80%; text-transform:uppercase;">Concept2</strong>
      </v-btn>

      <!-- <v-btn v-if="!$oauth.isConnected(profile, 'huawei')" :href="providerUrls.huawei" color="#CF152D" class="connect-button mr-4 mb-4 white--text">
        <span style="font-size:80%;" class="mr-1">Connect with</span>
        <strong style="font-size:80%; text-transform:uppercase;">Huawei</strong>
      </v-btn>  -->

      <p>{{ $t('profile.connections.step-tracking-msg') }}</p>
      <p>
        <v-btn outlined href="https://corporatefitness.helpscoutdocs.com/category/15-device-integrations" target="_blank" color="info" class="mt-4">
          <v-icon small class="mr-2">fal fa-lightbulb-on</v-icon> {{$t('shared.tips')}}
        </v-btn><br/>
        <span class="text-muted">{{$t('profile.connections.troubleshooting')}}</span>
      </p>
    </v-card-text>      

    <v-divider />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileConnectPanel",
  props: {
    profile: Object,
    providerUrls: Object,
  },
  data() {
    return {
      tenant: tenant,
    };
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style lang="scss">
.connect-button-container {
  display: flex;
  flex-direction: column;

  .connect-button {
    display: flex;
    // margin: 0 auto;
    width: 300px;
    min-width: 300px;
    max-width: 300px;

  }
}

.view--embedded.size--xs .connect-button { width: 100%;}
</style>