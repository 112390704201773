<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="profile" :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2">{{$t('profile.connections.title')}}</v-card-title>
      <v-alert v-if="isConnectSuccessfully" type="success" prominent class="mx-4 mt-8">All good, activities you save on your device/app will be automatically synced from now on.</v-alert>

      <ProfileConnectPanel :profile="profile" :providerUrls="providerUrls" />

      <div v-if="$oauth.hasConnection(profile)">
        <v-card-title class="subtitle">{{$t('profile.connections.status')}}</v-card-title>
        <v-card-text>
          <v-alert type="info">
            <i18n path="profile.connections.configured">
              <strong>{{profile.providers.map(x => $options.filters.titleize(x)).join(' + ')}}</strong>
            </i18n>
          </v-alert>
          <p v-if="!profile.sync">{{$t('profile.connections.status-empty')}}</p>
          <v-alert v-else-if="profile.sync.status == 'OK'" type="success">{{$t('profile.connections.status-success', { timestamp: $options.filters.localDate(profile.sync.timestamp, "L LTS") })}}</v-alert>
          <v-alert v-else-if="profile.sync.status == 'INFO'" type="info">{{$t('profile.connections.status-info', { timestamp: $options.filters.localDate(profile.sync.timestamp, "L LTS"), message: profile.sync.msg })}}</v-alert>
          <v-alert v-else type="error">{{$t('profile.connections.status-error', { timestamp: $options.filters.localDate(profile.sync.timestamp, "L LTS"), message: profile.sync.msg })}}</v-alert>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'strava')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.strava-options')}}</v-card-title>
        <v-card-text>
          <p v-if="false">{{$t('profile.connections.strava-intro')}}</p>
          <p v-if="false">
            <v-btn outlined :href="providerUrls.strava_all" color="primary" class="">
              {{$t('profile.connections.strava-access-to-private')}}
            </v-btn>
          </p>
          <p>{{$t('profile.connections.strava-explenation')}}</p>
          <v-btn outlined @click="pull('strava')" color="primary" :loading="$store.getters.isLoading && pullingSource=='strava'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('strava')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
          <v-btn text href="https://corporatefitness.helpscoutdocs.com/article/23-strava" target="_blank" color="info" class="ml-4">
            <v-icon small class="mr-2">fal fa-lightbulb-on</v-icon> {{$t('shared.tips')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'garmin')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.garmin-options')}}</v-card-title>
        <v-card-text>
          <p>{{$t('profile.connections.garmin-intro')}}</p>
          <v-btn outlined @click="pull('garmin')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='garmin'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('garmin')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'fitbit')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.fitbit-options')}}</v-card-title>
        <v-card-text>
          <p>{{$t('profile.connections.generic-intro')}}</p>
          <v-btn outlined @click="pull('fitbit')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='fitbit'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('fitbit')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
          <v-btn text href="https://corporatefitness.helpscoutdocs.com/article/53-fitbit" target="_blank" color="info" class="ml-4">
            <v-icon small class="mr-2">fal fa-lightbulb-on</v-icon> {{$t('shared.tips')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'polar')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.polar-options')}}</v-card-title>
        <v-card-text>
          <p>{{$t('profile.connections.generic-intro')}}</p>
          <v-btn outlined @click="pull('polar')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='polar'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('polar')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
          <v-btn text href="https://corporatefitness.helpscoutdocs.com/article/48-polar" target="_blank" color="info" class="ml-4">
            <v-icon small class="mr-2">fal fa-lightbulb-on</v-icon> {{$t('shared.tips')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'suunto')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.suunto-options')}}</v-card-title>
        <v-card-text>
          <p>{{$t('profile.connections.generic-intro')}}</p>
          <v-btn outlined @click="pull('suunto')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='suunto'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('suunto')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'underarmour')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.underarmour-options')}}</v-card-title>
        <v-card-text>
          <p>{{$t('profile.connections.generic-intro')}}</p>
          <v-btn outlined @click="pull('underarmour')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='underarmour'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('underarmour')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'coros')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.coros-options')}}</v-card-title>
        <v-card-text>
          <p>{{$t('profile.connections.generic-intro')}}</p>
          <v-btn outlined @click="pull('coros')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='coros'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('coros')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'concept2')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.concept2-options')}}</v-card-title>
        <v-card-text>
          <p>{{$t('profile.connections.generic-intro')}}</p>
          <v-btn outlined @click="pull('concept2')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='concept2'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('concept2')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'oura')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.device-options', {device: 'Oura Ring'})}}</v-card-title>

        <v-card-text>
          <p>{{$t('profile.connections.generic-intro')}}</p>
          <v-btn outlined @click="pull('oura')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='oura'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('oura')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'whoop')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.device-options', {device: 'Whoop'})}}</v-card-title>

        <v-card-text>
          <v-alert type="info" outlined>
            <p>The Whoop integration is currently in beta phase. Please share your feedback with us.</p>
            <p>
              <v-btn :to="{name: 'contact'}">Contact Us</v-btn>
            </p>
          </v-alert>
          <p>{{$t('profile.connections.generic-intro')}}</p>
          <v-btn outlined @click="pull('whoop')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='whoop'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn>
          <v-btn outlined @click="disconnect('whoop')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
        </v-card-text>      
      </div>

      <div v-if="$oauth.isConnected(profile, 'huawei')" class="pt-0">
        <v-divider />
        <v-card-title class="subtitle">{{$t('profile.connections.device-options', {device: 'Huawei'})}}</v-card-title>

        <v-card-text>
          <v-alert type="info" outlined>
            <p>The Huawei integration is currently in beta phase. Please share your feedback with us.</p>
            <p>
              <v-btn :to="{name: 'contact'}">Contact Us</v-btn>
            </p>
          </v-alert>
          <p>{{$t('profile.connections.generic-intro')}}</p>
          <!-- <v-btn outlined @click="pull('huawei')" color="primary"  :loading="$store.getters.isLoading && pullingSource=='huawei'">
            {{$t('profile.connections.pull-last-week')}}
          </v-btn> -->
          <v-btn outlined @click="disconnect('huawei')" color="error" class="ml-4">
            <v-icon small class="mr-2">fal fa-times</v-icon> {{$t('shared.disconnect')}}
          </v-btn>
        </v-card-text>      
      </div>

      <v-snackbar v-model="syncOkSnackbar" top color="success" :timeout="6000">{{$t('profile.connections.syncing-progress-message')}}</v-snackbar>
      <v-snackbar v-model="syncErrorSnackbar" top color="error" :timeout="6000">{{$t('profile.connections.sync-error-message')}}</v-snackbar>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";
import ActivityGrid from '@/components/ActivityGrid.vue';
import ProfileConnectPanel from '@/components/ProfileConnectPanel.vue';
import Header from './_Header.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    EventGrid,
    ActivityGrid,
    ProfileConnectPanel,
    Header,
  },
  props: {
  },
  data() {
    return {
      profile: null,
      syncOkSnackbar: false,
      syncErrorSnackbar: false,
      pullingSource: null,
      providerUrls: null,
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Connections', disabled: true },
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });

  },
  methods: {
    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;
        const options = { profileId: this.profile.id, view: this.viewMode };
        this.providerUrls = this.$oauth.getProviderUrls(options);

        var response = await profileService.getConnections();
        console.log('Connection status:', response.data);
        //console.log('oauth providers', this.$oauth.mergeOptions(options), this.providers);
      }
    },

    async disconnect(provider) {
      if (this.user) {
        try {
          await profileService.disconnect(provider);
          await this.getProfile();
        }
        catch {
          this.syncErrorSnackbar = true;
        }
      }
    },

    async pull(provider) {
      this.pullingSource = provider;
      if (this.user) {
        try {
          await profileService.pull(provider);
          this.syncOkSnackbar = true;
        }
        catch {
          this.syncErrorSnackbar = true;
        }
      }
    },

  },
  computed: {
    isConnectSuccessfully() {
      return this.$route.query.connect === 'success';
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    viewMode() {
      return this.$route.query.view || this.$store.state.view;
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

